<template>
  <v-app id="app" :class="`${isEmbedded?'view--embedded':'view--normal'} tenant--${tenant.id} size--${$vuetify.breakpoint.name} context--${$store.state.context} theme--${$store.state.theme} ${$route.meta.contentLayout ? 'layout--content' : ''} ${$route.meta.layoutClass ? 'layout--' + $route.meta.layoutClass : ''}`">
    <div v-if="tenant.id === 'default' && !isEmbedded" class="header-announcement">
      <span>📢 sodisp is rebranding as <a href="https://fitmap.app" target="_blank">FitMap</a>.</span>
      <a href="https://fitmap.app" target="_blank">Learn More &raquo;</a>
    </div>
    <div v-if="tenant.id === 'fitmap' && !isEmbedded" class="header-announcement">
      <span>📢 Welcome to FitMap, previously known as sodisp. You can keep using your same account.</span>
      <a href="/" target="_blank">Learn More &raquo;</a>
    </div>
    <div v-if="tenant.id === 'truenorth' && !isEmbedded" class="header-announcement primary">
      <span>Find your True North</span>
    </div>
    <div v-if="tenant.id === 'cofi' && !isEmbedded && now < DateTime.utc(2023, 8, 26)" class="header-announcement">
      <span>📢 Get ready for <strong><u>Step</u>tember</strong> 👟💪! We can still <a href="/contact" class="white--text">help you go live in time</a>.</span>
      <a href="/contact">Contact Us &raquo;</a>
    </div>
    <div v-else-if="tenant.id === 'cofi' && !isEmbedded" class="header-announcement">
      <span>Planning your next company step or fitness challenge? <a href="/contact" class="white--text">Schedule a Call or Demo</a> to get started.</span>
      <a href="/contact" class="white--text">Schedule a Call &raquo;</a>
    <!-- <span>Want to see how easy to use our platform is? <a href="/start-trial" class="white--text">Create a free trial event</a> in 3 clicks.</span>
      <a href="/start-trial" class="white--text">Start a Trial &raquo;</a> -->
    </div>
    <v-app-bar
      v-if="!isEmbedded"
      app
      color="header"
      :class="$route.meta.toolbarClass"
      :dark="tenant.toolbarDark"
      extended
      extension-height="4"
      elevate-on-scroll
    >

      <v-app-bar-nav-icon v-if="(newCofiMenu && $vuetify.breakpoint.smAndDown) || (!newCofiMenu && $vuetify.breakpoint.xs)" @click="drawer = true" title="Navigation Menu Toggle"><v-icon>fa-bars</v-icon></v-app-bar-nav-icon>
      <router-link to="/" class="pa-0 logo">
        <span v-if="tenant.id === 'cofi'" class="d-flex">
          <img v-if="true" :src="tenant.headerLogoUrl" alt="Logo CorporateFitness.app" class="ms-4" height="40" style="max-width:200px;" />
          <span class="ms-4 header-logo white--text" style="line-height:40px;">Corporate<strong>Fitness</strong>.app</span>
        </span>
        <img v-else :src="tenant.headerLogoUrl" alt="Logo sodisp" class="ms-4" height="32" style="max-width:200px;" />
      </router-link>
      <!--<v-toolbar-title><v-btn text to="/" exact class="px-0">sodisp</v-btn></v-toolbar-title>--> 
      <v-spacer/>
      
      <CmsMenu v-if="newCofiMenu && $vuetify.breakpoint.mdAndUp" />
      <template v-else-if="!newCofiMenu && !$vuetify.breakpoint.xsOnly">
        <template v-for="(item, i) in $store.state.nav['menu-'+tenant.navPostfix]">
          <v-btn :key="i" text :to="item.to" exact>
            <v-icon class="me-2 d-none">{{item.icon}}</v-icon>
            {{ item.text_key ? ( item.text_key.indexOf('.') > 0 ? $t(item.text_key) : $t('nav.header.' + item.text_key)) : item.text }}
          </v-btn>
        </template>
        <v-menu offset-y v-if="tenant.solutionsMenuKey">
          <template v-slot:activator="{ on }">
            <v-btn text class=" ml-0" v-on="on">
              {{ tenant.solutionsTranslationKey ? $t(tenant.solutionsTranslationKey) : $t('nav.header.solutions') }}
              <v-icon :color="tenant.toolbarDark ? 'white' : 'black'" class="ms-1">fal fa-angle-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <template v-for="(item, i) in $store.state.nav[tenant.solutionsMenuKey]">
                <v-divider v-if="item.divider" :key="i" class="my-4" />
                <v-list-item v-else :key="i" link :to="item.to" exact>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text_key ? ( item.text_key.indexOf('.') > 0 ? $t(item.text_key) : $t('nav.account.' + item.text_key)) : item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-menu>              
      </template>
      <v-spacer v-if="tenant.toolbarCentered"/>

      <AccountMenu />
      <v-progress-linear slot="extension"  v-if="isHttpRequestLoading" indeterminate color="progress" class="ajax-loader ma-0"></v-progress-linear>
    </v-app-bar>

    <CmsDrawer v-if="newCofiMenu" v-model="drawer" />
    <v-navigation-drawer 
      v-else
      v-model="drawer"
      fixed
      temporary
      :right="$vuetify.rtl"
    >
      <v-list-item to="/">
        <v-list-item-content>
          <v-list-item-title v-if="tenant.id === 'cofi'" class="title">
            <div><img src="/images/logo-cofi-sidebar.png" alt="Logo CorporateFitness.app" width="200" /></div>
          </v-list-item-title>
          <v-list-item-title v-else class="title">
            <div><img :src="tenant.logoUrl" alt="Logo sodisp" width="200" /></div>
            <!--<strong>sodisp</strong>.com-->
          </v-list-item-title>
          <v-list-item-subtitle v-if="tenant.id === 'cofi'">
            Connected Corporate Fitness
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>
            Challenge Platform
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider dark class="my-4"/>
      <v-list dense>
        <template v-for="(item, i) in $store.state.nav['drawer-'+tenant.navPostfix]">
          <v-list-item v-if="item.heading || item.heading_key" :key="i">
            <v-list-item-content>
              <v-list-item-title class="subtitle">
                {{ item.heading_key ? $t('nav.header.' + item.heading_key) : item.heading }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-else-if="item.divider" :key="i" dark class="my-4" />
          <v-list-item v-else :key="i" link :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ item.text_key ? $t('nav.header.' + item.text_key) : item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item v-if="tenant.id === 'cofi'" class="mt-8">
          <v-btn color="primary" block to="/eventmanager/create">Create an Event</v-btn>
        </v-list-item>
      </v-list>
 
      <!--<template v-slot:append>
        <div class="pa-2">
          <v-btn color="primary" block to="/me">My Profile</v-btn>
        </div>
      </template>   -->  
    </v-navigation-drawer>    
    <!-- <v-progress-linear v-if="isHttpRequestLoading" indeterminate color="yellow darken-2" class="ajax-loader"></v-progress-linear> -->
    <div v-if="$route.meta.contentLayout" id="page-hero" class="container px-0 pt-6 pt-md-12 white--text container--fluid">
      <div class="container">
        <h1 v-if="!pageImage || tenant.id == 'cofi' || tenant.id == 'fitmap'" class="display-2 mt-16">{{pageTitle}}</h1>
        <v-row v-else>
          <v-col cols="12" sm="8" class="d-flex">
            <h1 class="display-2 align-self-end" :class="{'display-1': $vuetify.breakpoint.xs}">{{pageTitle}}</h1>
          </v-col>
          <v-col cols="12" sm="4" class="align-center">
            <img class="width-50p illustration" :src="pageImage" :alt="pageTitle" /> 
          </v-col>
        </v-row>
      </div>
    </div>
    <router-view id="content" v-if="noLayout"></router-view>
    <v-container v-if="!noLayout" id="content">
      <router-view></router-view>
    </v-container>
    <Footer />
    <ChatBot/>
  </v-app>
</template>

<style v-if="tenant.customCss" :src="`/styles/${tenant.customCss}`"></style>


<style src="./assets/css/site.css"></style>
<style src="./styles/global.scss" lang="scss"></style>
<style lang="scss">
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  .ajax-loader.v-progress-linear {
    /*top: 64px;*/
  }
  .v-app-bar a.logo { line-height: 0;}
  .v-toolbar__title .v-btn { text-transform: inherit !important; line-height: 18px; font-weight: 700;; font-size: 18px; letter-spacing: 0 !important; }
  .v-toolbar__title .v-btn:before { display: none; }
  .v-toolbar__content, .v-toolbar__extension { padding: 0 !important; }
  /*.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child, .v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:last-child { margin-right: 0!important;}*/
  .v-application--is-ltr .v-toolbar__content > .v-btn:last-child, .v-application--is-ltr .v-toolbar__extension > .v-btn:last-child { margin-right: 8px!important;}
  body header.v-app-bar--is-scrolled {
    background: linear-gradient(to right, var(--v-header-base), var(--v-gradient-base)) !important; 
  }
  .v-list-item__title.allow-wrap { white-space: unset;}

  .view--embedded {
    .v-main__wrap > .container { padding-top: 0;}
    .v-breadcrumbs { padding-top: 0; }
    #content { min-height: 300px;}
  } 

  /* CLS improvements:  */
  #content { min-height: 900px;}
  #content > .v-main__wrap { min-height: 900px;}
  #content > .v-main__wrap > div.container { min-height: 900px;}
  #content > .v-main__wrap > div.container > div { min-height: 900px;}
  .v-application--wrap > #page-hero { height: 255px;}

  @media (max-width: 500px) {
    .v-application--wrap > #page-hero { height: 200px;}
  }

</style>

<script>

  import { EventBus } from '@/plugins/eventbus.js';
  import { DateTime } from 'luxon'
  import VueCookies from 'vue-cookies'
  import isRtlLanguage from '@/util/i18n/rtl-languages.js'
  import AccountMenu from "@/components/AccountMenu.vue";
  import LanguageMenu from "@/components/LanguageMenu.vue";
  import ChatBot from "@/components/ChatBot.vue";
  import Footer from "@/Footer.vue";
  import CmsMenu from '@/components/cms/CmsMenu.vue';
  import CmsDrawer from '@/components/cms/CmsDrawer.vue';
  import tenants from '@/data/tenants.config'
  const tenant = tenants.current();
  
  //import(tenant.customCss); 

  export default {
    props: {
      source: String,
    },
    components: {
      AccountMenu,
      LanguageMenu,
      CmsMenu,
      CmsDrawer,
      ChatBot,
      Footer,
    },
    data: () => ({
      newCofiMenu: true && tenant.id == 'cofi',
      drawer: false,
      titleOverride: null,
      imageOverride: null,
      tenant: tenant,
      now: DateTime.now(),
      DateTime: DateTime,
    }),
    mounted() {
      if (tenant.customCss) {
        // Must add as dynamic element to prevent webpack from optimizing this away
        let link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `/styles/${tenant.customCss}`;
        document.head.appendChild(link);
      }
      if (tenant.iOSAppStoreId){
        this.addMetaTag("apple-itunes-app", `app-id=${tenant.iOSAppStoreId}`);
      }
      if (tenant.androidPackageId){
        // todo: see https://developers.google.com/web/fundamentals/app-install-banners/native
      }
      if (tenant.favIconUrl) {
        document.getElementById('favicon').href = tenant.favIconUrl;
      }

      let params = new URLSearchParams(window.location.search);
      const view = params.get("view"); // note: this.$route.query.view doesn't work on hard-refreshes!
      if (view) {
        this.$store.commit("setViewMode", view);
      }
      let theme = params.get("theme"); // note: this.$route.query.view doesn't work on hard-refreshes!
      if (theme) {
        this.$store.commit("setTheme", theme);
      }
      theme = theme || this.$store.state.theme || VueCookies.get('theme');
      if (theme == 'highcontrast') {
        this.$vuetify.theme.themes.light = tenant.themeHighContrast;
      }

      window.addEventListener('click', event => {
        // ensure we use the link, in case the click has been received by a subelement
        let { target } = event;
        while (target && target.tagName !== 'A') target = target.parentNode
        // handle only links that do not reference external resources
        if (target && target.matches(".clickToPlayVideo")) {
          var iframe = document.createElement('iframe');
          iframe.src = `https://www.youtube.com/embed/${target.dataset.videoId}?autoplay=1&origin=https://corporatefitness.app`;
          iframe.class = 'full-dialog';
          iframe.style = 'border:0;max-width:100%;height:360px;width:640px;'
          iframe.allowfullscreen = 'allowfullscreen';
          iframe.allow = 'autoplay;fullscreen';
          target.textContent = '';
          target.appendChild(iframe);
          return;
        }
        if (target && target.matches(".dynamic-content a:not([href*='://'])") && target.href) {
          // some sanity checks taken from vue-router:
          // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
          const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
          // don't handle with control keys
          if (metaKey || altKey || ctrlKey || shiftKey) return
          // don't handle when preventDefault called
          if (defaultPrevented) return
          // don't handle right clicks
          if (button !== undefined && button !== 0) return
          // don't handle if `target="_blank"`
        
          if (target && target.getAttribute) {
            const linkTarget = target.getAttribute('target')
            if (/\b_blank\b/i.test(linkTarget)) return
          }
          // don't handle same page links/anchors
          const url = new URL(target.href)
          const to = url.pathname
          if (window.location.pathname !== to && event.preventDefault) {
            event.preventDefault()
            this.$router.push(to)
          }
        }
      });
    },
    methods: {
      addMetaTag(name, content) {
        let meta = document.createElement('meta');
        meta.name = name;
        meta.content = content;
        document.head.appendChild(meta);

      },
      resetViewMode() {
        this.$store.dispatch('setViewMode', null);
      },
    },
    computed: {
      noLayout() {
        return this.$route.matched.some(route => route.meta.noLayout); //$route.meta.noLayout;
      },
      isHttpRequestLoading () {
        return this.$store.getters.loading
      },
      pageTitle() {
        return this.titleOverride || this.$route.meta.title;
      },
      pageImage() {
        return this.imageOverride || (this.$route.meta.illustration ? 'https://sodisp.imgix.net/web/illustrations/'+this.$route.meta.illustration : null);
      },
      useHighContrast() {
        return this.$store.state.theme === 'highcontrast' || this.$route.query.theme === 'highcontrast';
      },
      isEmbedded() {
        return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.$route.query.view === 'app' || this.$store.state.view == 'app';
      },
    },
    watch: {
      '$i18n.locale': function(newVal, oldVal) {
        console.log('locale change', newVal);
        this.$vuetify.rtl = isRtlLanguage(newVal);
      }
    }

  }
</script>